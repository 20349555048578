import React from "react";
import "../styles/index.css";

export default (props) => (
	<div
		className={"w3-xlarge w3-dark-gray w3-round-xxlarge w3-cell"}
		style={{ width: `100%` }}
	>
		<div className={props.color} style={props.width}>
			{props.percent}%
		</div>
	</div>
);
