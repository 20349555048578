import React from "react";
import Layout from "../components/layout";
import { Accordion } from "react-accessible-accordion";
import AccordionSection from "../components/accordionSection";
import Westside from "../images/westside.svg";

import "../styles/w3.css";

const InTheBagPage = () => (
	<Layout title="In The Bag">
		<br />

		<Accordion allowZeroExpanded="true" className="mobile-width">
			<AccordionSection
				title="Putters&ensp;"
				percent="26"
				width={{ width: `26%` }}
				color="w3-container w3-round-xxlarge w3-green"
			>
				<ul>
					<li>Beetle</li>
					<li>Swan Reborn</li>
					<li>Maiden</li>
					<li>Shield x2</li>
					<li>Harp</li>
				</ul>
			</AccordionSection>
			<AccordionSection
				title="Mids&ensp;&ensp;&nbsp;&nbsp;"
				percent="17"
				width={{ width: `17%` }}
				color="w3-container w3-round-xxlarge w3-blue"
			>
				<ul>
					<li>Tursas</li>
					<li>Gatekeeper</li>
					<li>Warship</li>
					<li>Anvil</li>
				</ul>
			</AccordionSection>
			<AccordionSection
				title="Fairway&nbsp;&nbsp;"
				percent="22"
				width={{ width: `22%` }}
				color="w3-container w3-round-xxlarge w3-deep-orange"
			>
				<ul>
					<li>Stag</li>
					<li>Longbowman x3</li>
					<li>Ahti</li>
				</ul>
			</AccordionSection>
			<AccordionSection
				title="Distance"
				percent="35"
				width={{ width: `35%` }}
				color="w3-container w3-round-xxlarge w3-red"
			>
				<ul>
					<li>Northman</li>
					<li>World x2</li>
					<li>Giant x4</li>
					<li>War Horse</li>
				</ul>
			</AccordionSection>
		</Accordion>

		<br />
		<img
			src={Westside}
			alt="Westside Discs Logo"
			style={{
				filter: ` invert() drop-shadow(0px 0px 15px black)`,
				maxWidth: `150px`,
				position: `absolute`,
				left: `50%`,
				transform: `translate(-50%, 0%)`,
			}}
		/>
	</Layout>
);

export default InTheBagPage;
