import React from "react";
import {
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel,
} from "react-accessible-accordion";
import Progress from "./progress.js";

import "../styles/w3.css";
import "../styles/accordion.css";

export default (props) => (
	<AccordionItem className="w3-mobile w3-margin-top w3-black w3-opacity-min w3-round-xxlarge">
		<AccordionItemHeading>
			<AccordionItemButton className="accordion__button w3-large w3-button w3-block w3-left-align w3-round-xxlarge w3-cell-row">
				<div className={"w3-container w3-cell w3-cell-middle"}>
					{props.title}
				</div>
				<Progress
					color={props.color}
					percent={props.percent}
					width={props.width}
				/>
			</AccordionItemButton>
		</AccordionItemHeading>
		<AccordionItemPanel>{props.children}</AccordionItemPanel>
	</AccordionItem>
);
